:root {
  font-family: "Open Sans", sans-serif;
}

.prevent-scrolling {
  height: 100vh;
  overflow: hidden;
}

thead {
  background: #f2f2f2 0% 0% no-repeat padding-box !important;
  color: #4d4d4d !important;
  letter-spacing: 0 !important;

  opacity: 1 !important;
  font-size: 12px !important;
}
.forgotspan {
  cursor: pointer;
}
.lastLogin {
  color: #aaa;
  text-align: center;
  font-size: 14px;
  margin-top: 35px;
}
.formmargin {
  margin-top: 100px;
}

.enable-label {
  position: relative;
  top: 3px;
}

.low-security-note {
  margin-top: 30px;
  font-size: 11px;
}

.edit-line {
  margin-top: 100px;
}

.edit-act-modal .form-group {
  margin-bottom: 20px;
}

.switch-on,
.switch-off {
  line-height: 18px !important;
}
.editheading {
  padding-bottom: 25px;
}
.loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  /*z-index: 100;*/
  width: 100vw;
  height: 100vh;
  background-color: white;
  background-image: url("../../assets/images/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
}
.error {
  font-size: 14px;
  margin-bottom: 0px;
  color: #f7931e;
}
.delete-signature-btn {
  background-color: #f6f8fa !important;
  color: #cf222e !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  margin-top: 20px;
  border-radius: 5px;
  border-color: rgba(31, 35, 40, 0.15) !important;
}
.delete-confirmation {
  color: #6c757d !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  margin-top: 14px;
}
.logout {
  cursor: pointer;
}
.adminoption {
  margin-bottom: 15px;
  letter-spacing: 0;
  color: #4d4d4d;
  font-size: 12px;
  box-sizing: border-box;
}
.adminoption.selected {
  border-left: 5px solid #39b54a;
  padding-left: 7px;
}
.admin,
.adminname,
.adminoption {
  padding-left: 12px;
}
.paddingsearchleft {
  padding-left: 0px !important;
}
.paddingsearchright {
  padding-right: 0px !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.marginside {
  margin-top: 25px;
}
.loginbtn1.create-account-btn {
  width: calc(100% - 90px);
  margin: 20px 45px;
}
.loginbtn1.edit-account-btn,
.loginbtn2.edit-account-btn {
  width: calc(100% - 30px);
  margin: 0px 15px;
}
.loginbtn2.edit-account-btn.change-state-btn {
  margin-bottom: 20px;
}
.adminname {
  font-size: 16px;
  font-weight: bold;
  color: #4d4d4d;
  opacity: 1;
  top: 75px;
  left: 27px;
  /* width: 108px; */
}
.cross {
  font-size: 11px !important;
}
.admin {
  font-size: 14px;
  color: #4d4d4d;
  line-height: 16px;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.65 !important;
}
select[name="site"] {
  min-width: 230px;
  padding: 10px;
  border-radius: 4px;
  height: 41px;
  font-size: 14px;
  border: 1px solid #ced4da;
}
.sidebar {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  min-height: 92vh;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.sidepadding {
  padding: 0 75px 75px !important;
}
.addaccount-container {
  margin-left: auto;
  margin-right: 0;
}
.abovetable {
  margin-bottom: 1rem;
}
.addaccount {
  background: #39b54a 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  padding: 5px !important;
  font-size: 12px !important;
  float: right;
}

.grader-users-heading-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

select[name="hourToSend"] {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  height: 41px;
  font-size: 14px;
  border: 1px solid #ced4da;
}

.green-button {
  background: #39b54a 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  padding: 5px !important;
  font-size: 12px !important;
}
.rowmargin {
  margin-top: 25px;
}
.searchbar {
  border: 1px solid #cccccc;
  border-radius: 3px;
  opacity: 1;

  width: 110%;
  /*margin-top: 100px;*/
  margin-bottom: 15px;
}
.editbtn {
  color: #39b54a !important;
  cursor: pointer;
}
.table td,
.table th {
  letter-spacing: 0 !important;
  color: #4d4d4d !important;
  opacity: 1;
  font-size: 12px !important;
  padding: 0.75rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  min-width: calc(100% / 8) !important;
}

.formfont {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #999999;
  opacity: 1;
  margin-top: 5px;
}

.check {
  width: 15px !important;
  margin-top: -5px !important;
}
.btn-secondary {
  color: #fff;
  background-color: green !important;
  border-color: #6c757d;
}
.cancle {
  float: right;
  background: white !important;
  border: white !important;
  /* margin-top: -55px !important; */
  color: black !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
  padding: 0 !important;
}
.modal-heading {
  margin-bottom: 0.5rem;
  float: left;
}
*:focus {
  outline: none;
}
.editscreen {
  width: 500px;
  margin: 100px auto;
}
.hrpadding {
  padding-bottom: 25px;
  clear: both;
}
.hrpadding1 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.hrpadding2 {
  padding-bottom: 25px;
}
.center {
  text-align: center;
  padding-bottom: 20px;
}
.centeruser {
  margin-top: -35px !important;
  text-align: center;
  padding-bottom: 20px;
}

.navbar {
  background: #ffffff;
  height: 58px;
  border-bottom: 1px solid #1ea030;
}

.centerrecover {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 30px;
}
.styles_closeButton__20ID4 {
  display: none !important;
}
.styles_modal__gNwvD {
  padding: 0px !important;
}
h2 {
  text-align: center;
}
thead {
  background: black;
  color: white;
}

.tablebox {
  border: 1px solid #f2f2f2;
  /*box-shadow: 1px 6px 19px 0px grey;*/
}
.custommodal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.loginbtn2 {
  opacity: 1;
  margin: 20px 30px;
  border-radius: 7px !important;
  width: 85%;
  background: white;
  background-color: white !important;
  color: black !important;
  font-weight: 600 !important;
  border-color: rgb(95, 179, 88) !important;
  float: right;
  margin-left: 10px;
}

.loginbtn2.warning {
  border-color: #ef5a25 !important;
}
.loginbtn2.meh {
  border-color: #f6941f !important;
}
.loginbtn2.success {
  border-color: rgb(95, 179, 88) !important;
}
.loginbtn2.warning.fill {
  background-color: #ef5a25 !important;
}
.loginbtn2.meh.fill {
  background-color: #f6941f !important;
}
.loginbtn2.success.fill {
  background-color: rgb(95, 179, 88) !important;
}
.loginbtn2.fill {
  color: white !important;
}

.loginbtn1 {
  opacity: 1;
  margin-top: 20px;
  width: 85%;
  border-radius: 7px !important;
  margin-right: 30px;
  background-color: rgb(95, 179, 88) !important;
  color: white !important;
  border-radius: 7px !important;
  border-color: rgb(95, 179, 88) !important;
  float: right;
  margin-left: 10px;
}
.custommodalinner {
  width: 900px !important;
  position: absolute;
  left: 33%;
  right: 33%;
  top: 10%;
  bottom: 10%;
  overflow-y: scroll;
  margin: auto;
  border-radius: 20px;
  background: white;
  box-shadow: 1px 1px 24px 1px #495057;
}
.delete {
  width: 25px;
}
.reset {
  margin-left: -10px;
  margin-right: -10px;
  width: 48px;
}

.edit {
  margin-left: -10px;
  margin-right: -10px;
  width: 44px;
  padding-left: 10px;
  padding-right: 10px;
}

.tablepadding {
  padding-left: 200px;
  padding-right: 200px;
}
.logo1 {
  width: 120px;
  margin-left: 70px;
}
.App {
  text-align: center;
}
.languages {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}
.chooseLanguageText {
  color: white;
  top: 8px;
  position: relative;
  margin-right: 20px;
}
.languages select {
  background: none;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 4px;
  height: 41px;
  font-size: 14px;
  /* border: 1px solid #ced4da; */
  /* padding: 20px; */
}

.languages option {
  color: black;
}
.languages button {
  background: none;
  border: none;
}
.languages button:disabled {
  background: rgba(255, 255, 255, 0.273);
  opacity: 1 !important;
}
.languages button:hover:enabled {
  background: rgba(255, 255, 255, 0.273);
}
.loginpad {
  border-radius: 5px;
  background: white;
  padding: 30px;
  padding-top: 30px;
  padding-bottom: 90px;
  height: 100%;
}
.imgcenter {
  text-align: center;
}
.loginpad1 {
  border-radius: 5px;
  box-shadow: 1px 1px 24px 1px green;
  background: white;
  padding: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.extra {
  background-color: powderblue;
  width: 50% !important;
  margin-top: 20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.checkbox {
  margin: 0px 5px 0 15px;
}
.checkbox-label {
  margin: 5px 30px 0 0;
}
.loginclr {
  color: white !important;
}
.App-link {
  color: #61dafb;
}
.loginbtn {
  background-color: #39b54a !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  margin-top: 20px;
  border-radius: 5px;
  border-color: #39b54a !important;
}
.domain-name {
  position: relative;
  font-weight: bold;
  top: 6px;
  left: -20px;
}
.phone-prefix {
  position: relative;
  top: 6px;
}
.loginbtn1 {
  opacity: 1;
  margin-top: 25px;
  width: 85%;
  margin-right: 30px;
  background-color: rgb(95, 179, 88) !important;
  color: white !important;
  border-radius: 7px !important;
  border-color: rgb(95, 179, 88) !important;
  float: right;
  margin-left: 10px;
}

.custom-pagination {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.custom-pagination > div {
  border: 1px solid #dee2e6;
  padding: 10px;
  cursor: pointer;
}

.rowcount-text {
  align-self: center;
}

.grader-name {
  text-transform: lowercase;
}

.grader-name::first-letter {
  text-transform: uppercase;
}

.patient-gender {
  text-transform: lowercase;
}

.patient-gender::first-letter {
  text-transform: uppercase;
}

.patient-details-container .patient-gender {
  display: inline-block;
}

.disclaimer {
  margin-top: 30px;
  font-size: 10px;
}

.table-error {
  height: 500px;
  width: 100%;
}
.table-error p {
  text-align: center;
  line-height: 500px;
  width: 100%;
  height: 500px;
}

.table-error img {
  text-align: center;
  line-height: 500px;
}

.exam-heading {
  margin-bottom: 20px;
}

.logos {
  height: 100%;
  margin-left: 30px;
}

.vfi-logo {
  height: 100%;
  position: relative;
  top: 0px;
}

.logo-container {
  height: 100%;
  padding: 0 56px 0 12px !important;
  flex-direction: row-reverse !important;
  text-align: right;
}

.version-number {
  margin-top: 50px;
  font-size: 10px;
}

.show-eye-statistics {
  color: #39b54a !important;
  cursor: pointer;
}

.pie-chart-container {
  width: 600px;
  margin: 0 auto;
}

.generate-report-without-images-modal.loginpad {
  position: relative;
}

.generate-report-modal .col-md-2 {
  padding-left: 0 !important;
}

.generate-report-without-images-modal .col-md-10 > div,
.generate-report-without-images-modal textarea {
  width: 100% !important;
}

.generate-report-without-images-modal h5 {
  margin-bottom: 20px;
  font-size: 1.1rem;
}

.generate-report-without-images-modal .aggregate-subheading {
  font-size: 0.9rem;
}

.generate-report-without-images-modal .aggregate-table-container {
  font-size: 0.9rem;
}

.generate-report-modal h5 {
  font-size: 1.1rem;
}

.search-container {
  display: grid;
  grid-template-columns: 300px 300px 1fr 4fr;
  align-items: center;
  justify-content: center;
}

.search-container.normal {
  grid-template-columns: 11fr 9fr 9fr;
}

.grader-container .grader-report-name {
  margin-top: 0;
  margin-bottom: 20px;
}

.search-container > * {
  margin: 20px 0;
}

.search-container select {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  width: 100%;
}

.site-settings select {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  width: 100%;
}

.site-settings-btn {
  width: 100%;
  margin: 0;
}

.site-settings .firstpage {
  padding-bottom: 70px;
}

.site-settings h6 {
  margin: 40px 0 20px;
}

.site-settings h6:first-child {
  margin-bottom: 20px;
  margin-top: 0;
}

.search-container .or-box,
.search-container .formfont {
  text-align: center;
}
.sort-container {
  margin-left: 30px;
}

.sort-container .dropdown-menu {
  font-size: 14px;
}

.custommodal .delete-user-admin-text {
  text-align: center;
}

.custommodaldeleteuser {
  width: 600px !important;
  position: absolute;
  left: 33%;
  right: 33%;
  top: 40%;
  overflow-y: scroll;
  margin: auto;
  border-radius: 20px;
  background: white;
  box-shadow: 1px 1px 24px 1px #495057;
}

.entitlement-settings.custommodalinner {
  bottom: 30%;
}

.gateway-settings-btn {
  width: 100%;
  margin: 0;
}

.gateway-settings.custommodalinner {
  top: 10%;
  bottom: 10%;
}

.custommodaldeleteuser .hrpadding {
  padding: 0;
}

.custom-add-exam-modal .hrpadding {
  padding: 0;
}

.custom-add-exam-modal .custom-heading {
  margin-bottom: 30px;
}

.custom-add-exam-patient-modal textarea.form-control {
  width: 100%;
}

.custom-add-exam-patient-modal table td:nth-of-type(1) {
  width: 150px;
}

.custom-add-exam-patient-modal table td:nth-of-type(2) {
  width: 150px;
  text-align: center;
}

.custom-add-exam-patient-modal table td:nth-of-type(3) {
  width: 150px;
  text-align: center;
}

.custom-add-exam-patient-modal table input {
  display: inline-block;
  width: 80%;
  position: relative;
  top: 5px;
  text-align: center;
}

.custom-add-exam-patient-modal table thead {
  line-height: 70px;
}

.custom-add-exam-patient-modal table th {
  text-align: center;
  height: 40px;
}

.custom-add-exam-patient-modal table thead {
  background: #fff !important;
  color: #000;
  letter-spacing: initial;
  opacity: 1 !important;
  font-size: 16px !important;
}

.custom-add-exam-patient-modal table {
  margin: 0 auto;
}

.custom-add-exam-modal textarea.form-control {
  width: 100%;
}

.custom-add-exam-modal table td:nth-of-type(1) {
  width: 150px;
}

.custom-add-exam-modal table td:nth-of-type(2) {
  width: 150px;
  text-align: center;
}

.custom-add-exam-modal table td:nth-of-type(3) {
  width: 150px;
  text-align: center;
}

.custom-add-exam-modal table input {
  display: inline-block;
  width: 80%;
  position: relative;
  top: 5px;
  text-align: center;
}

.custom-add-exam-modal table thead {
  line-height: 70px;
}

.custom-add-exam-modal table th {
  text-align: center;
  height: 40px;
}

.custom-add-exam-modal table thead {
  background: #fff !important;
  color: #000;
  letter-spacing: initial;
  opacity: 1 !important;
  font-size: 16px !important;
}

.custom-add-exam-modal table {
  margin: 0 auto;
}

.loginbtn2.edit-account-btn.warning:disabled {
  opacity: 0.65;
}

.add-org-back {
  position: absolute;
  top: 8px;
  left: 30px;
  cursor: pointer;
  color: rgb(95, 179, 88) !important;
  font-size: 14px;
  z-index: 9999;
}

.edit-org-btn {
  background-color: rgb(95, 179, 88) !important;
  color: white;
  font-weight: normal;
}

.custom-flex-wrapper {
  display: flex;
  justify-content: space-between;
}

.back-link {
  color: rgb(95, 179, 88) !important;
  cursor: pointer;
}

.delete-link {
  color: #ef5a25 !important;
}

.device-selection-container {
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
  height: 40px;
  align-items: stretch;
}

.device-selection-container > * {
  flex-basis: 120px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.device-selection-container .device-selection-box {
  border: 1px solid rgb(95, 179, 88);
  border-radius: 5px;
  cursor: pointer;
}

.device-selection-container .device-selection-box.selected {
  background-color: rgb(95, 179, 88);
  color: white;
}

.patient-details {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.patient-name {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.patient-details .patient-mrn {
  margin-top: -5px;
}

.patient-details .patient-gender {
  margin-top: 8px;
}

.patient-details-big-container {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.exam-details {
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.exam-details .exam-date {
  font-size: 12px;
}

.exam-details .device-type {
  font-size: 12px;
  color: #aaa;
}

.patient-details-heading-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.patient-details-heading-container .patient-details-heading {
  font-size: 1.25rem;
  font-weight: 500;
}

.exam-details-page .right-heading-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 224px;
  margin-top: 20px;
}
.exam-details-page .right-heading-container .edit-patient-details {
  width: 360px;
}
.exam-details-page .right-heading-container .edit-patient-details img {
  float: right;
  width: 20px;
  cursor: pointer;
}

.patient-details-container.last {
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.medical-history {
  margin-top: 20px;
}

.medical-history .history-container {
  display: flex;
  justify-content: space-between;
}

.current-medication .medication-container {
  display: flex;
  justify-content: space-between;
}

.current-medication .edit-current-medication {
  width: 20px;
  cursor: pointer;
}

.allergies .allergies-container {
  display: flex;
  justify-content: space-between;
}

.allergies .edit-allergies {
  width: 20px;
  cursor: pointer;
}

.medical-history .edit-medical-history {
  width: 20px;
  cursor: pointer;
}

.medical-history .medical-history-heading {
  font-size: 1rem;
  font-weight: bold;
}

.allergies {
  margin-top: 20px;
  padding-bottom: 20px;
}

.allergies .allergies-heading {
  font-size: 1rem;
  font-weight: bold;
}

.exam-table {
  margin-top: 20px;
  width: 600px;
  text-align: center;
  line-height: 40px;
}

.exam-table tr td:first-child {
  text-align: left;
}

.exam-table thead {
  background: #fff !important;
  color: #000;
  letter-spacing: initial;
  opacity: 1 !important;
  font-size: 16px !important;
}

.current-medication {
  margin-top: 20px;
}

.patient-details-heading-container .edit-patient-details {
  width: 20px;
}

.data-sync-note {
  margin-top: 30px;
}

.current-medication .current-medication-heading {
  font-size: 1rem;
  font-weight: bold;
}

.exam-images .image-heading {
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 500;
}

.exam-images .pristine-heading-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.exam-images .pristine-heading-container .pristine-date {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.exam-images .pristine-heading-container .toggle-collection {
  width: 200px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
}

.exam-images .pristine-heading-container .toggle-collection .toggle {
  width: 100px;
  font-weight: bold;
  color: #222222;
  font-size: 14px;
  text-align: center;
  background-color: #f2f2f2;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exam-images .pristine-heading-container .toggle-collection .right-toggle {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.exam-images
  .pristine-heading-container
  .toggle-collection
  .right-toggle.selected {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.exam-images .pristine-heading-container .toggle-collection .left-toggle {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.exam-images
  .pristine-heading-container
  .toggle-collection
  .left-toggle.selected {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.exam-images .pristine-heading-container .toggle-collection .toggle.selected {
  background-color: #d9d9d9;
  border-radius: 5px;
  border-bottom: 3px solid #5fbb75;
}

.exam-images .image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: flex-start;
}

.exam-images .image-grid img {
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 20px;
}

.exam-images .image-grid a {
  position: relative;
  display: inline-block;
}

.exam-images .image-grid {
  position: relative;
}

.exam-images .image-grid div .download-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.disabled-download {
  color: #aaa;
  cursor: not-allowed;
}

.exam-images .image-grid a .download-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-dob .or-word {
  text-align: center;
  position: relative;
  top: 6px;
}

.report-megacontainer {
  display: flex;
}

.report-buttons-container {
  padding-bottom: 20px;
}

.customDatePicker,
.customDatePicker > div.react-datepicker-wrapper,
.customDatePicker
  > div
  > div.react-datepicker__input-container
  .customDatePicker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

div.react-datepicker__input-container input {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  width: 100%;
}

.custom-modal-add-patient.loginpad {
  position: relative;
}

.custom-modal-add-patient.loginpad select {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  width: 100%;
}
.custom-modal-add-patient.loginpad .submit-button {
  position: sticky;
  bottom: 20px;
  width: 100%;
  margin: 0;
}

.create-account-btn.create-org-btn {
  position: sticky;
  bottom: 20px;
  width: 100%;
  margin: 0;
}

.create-account-btn.custom-modal-btn {
  position: sticky;
  bottom: 20px;
  width: 100%;
  margin: 0;
}

.custom-add-exam-patient-modal.loginpad {
  position: relative;
}

.custom-add-exam-patient-modal.loginpad select {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  width: 100%;
}
.custom-add-exam-patient-modal .submit-button {
  position: sticky;
  bottom: 20px;
  margin: 0;
  width: 100%;
}

.firstpage > *:nth-last-child(2) {
  margin-bottom: 30px;
}

.report-buttons-container h5 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.report-buttons-container .view-report-container img {
  width: 50px;
}

.report-buttons-container .view-report-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 40px;
}

.report-buttons-container .view-report-container.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.exam-details .exam-details-heading {
  margin-bottom: 20px;
}

.firstpage textarea {
  width: 360px;
}

.select-grid-container {
  display: grid; /* 1 */
  grid-template-columns: repeat(4, 150px); /* 2 */
  grid-gap: 1rem; /* 3 */
  justify-content: space-between; /* 4 */
  margin-bottom: 40px;
}

.select-grid-container img {
  cursor: pointer;
  width: 100%;
  /* margin-bottom: 30px;
  margin-right: 30px; */
}

.prescription-container {
  display: flex;
  justify-content: space-between;
}

.org-subheading {
  padding-left: 15px;
  margin-bottom: 20px;
}

.input-email input {
  text-align: right;
}

.hrpadding.generate-report-line {
  padding: 0px;
}

.login-email {
  padding-bottom: 3px;
  margin-bottom: 0px;
}

.report-label {
  margin-top: 8px;
}

.edit-act-modal {
  bottom: 5% !important;
  width: 700px !important;
}

.create-modal {
  width: 700px !important;
}

.password-screen input[type="password"] {
  margin-top: 20px;
}
.login-attempts {
  padding-bottom: 3px;
  font-size: 14px;
  color: red;
}

.org-line {
  margin-top: 2rem;
}

.select-images-heading {
  margin-bottom: 20px;
}

.loginbtn1.create-account-btn.select-images-button {
  /* margin: 30px 0 0 0; */
  width: 100%;
}

.generate-report-modal.loginpad {
  position: relative;
}

.generate-report-modal.loginpad
  .loginbtn1.create-account-btn.select-images-button,
.generate-report-without-images-modal.loginpad
  .loginbtn1.create-account-btn.select-images-button {
  position: sticky;
  bottom: 20px;
  margin: 0;
  box-sizing: border-box;
}

.custom-add-exam-modal.loginpad {
  position: relative;
}

.custom-add-exam-modal.loginpad .loginbtn1.create-account-btn {
  position: sticky;
  bottom: 20px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
}

.image-grid-item {
  position: relative;
}

.image-grid-item .tick {
  display: none;
}

.image-grid-item .laterality {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 30px;
  display: block;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.image-grid-item.selected .tick {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  display: block;
}

.abovetable .green-button {
  margin-right: 20px;
}

.image-grid-videos div {
  position: relative;
}

.image-grid-videos .play-btn {
  position: absolute;
  width: 50px;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -36px;
}

.patient-details-container .patient-details-heading {
  font-weight: bold;
}

.patient-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
  margin-bottom: 10px;
}

.exam-table th {
  min-width: 50px;
}

.image-upload button {
  /* margin: 0; */
}

.image-upload.img-up-btn {
  width: 500px;
}

.image-upload.img-up-btn input {
  top: 20px;
}

.signature-container {
  text-align: center;
  width: 100%;
}

.signature-container .signature {
  object-fit: contain;
  width: 100%;
}

.editscreen .editheading.signature {
  margin-top: 40px;
}

.editscreen .editheading.signature.ad {
  margin-top: 0px;
}

.image-upload input {
  position: absolute;
  top: 0;
  left: 0; /* not left, because only the right part of the input seems to
               be clickable in some browser I can't remember */
  cursor: pointer;
  opacity: 0;
  filter: alpha(
    opacity=0
  ); /* and all the other old opacity stuff you
                               want to support */
  font-size: 300px; /* wtf, but apparently the most reliable way to make
                       a large part of the input clickable in most browsers */
  height: 43px;
  width: 100%;
}

.image-upload a {
  color: #39b54a !important;
  cursor: pointer;
}

.image-upload {
  position: relative;
}

.generate-report-modal.loginpad select {
  width: 375px;
  padding: 7px;
}

.generate-report-modal.loginpad textarea {
  height: 100px;
  padding: 7px;
  width: 404px;
}

.add-diagnosis {
  margin-left: 15px;
  font-size: 13px;
  color: rgb(95, 179, 88) !important;
  margin-bottom: 26px;
  text-align: center;
  cursor: pointer;
}

.custom-add-exam-patient-modal .hrpadding {
  padding: 0;
}

.custom-add-exam-patient-modal .modal-heading {
  margin: 20px 0 10px 0;
}

.custom-add-exam-patient-modal h6 {
  margin: 20px 0;
}

.custommodalinnerdatepicker {
  top: 3% !important;
  bottom: 3% !important;
  width: 900px !important;
}

.custommodaleditexamdetails {
  height: 270px;
}
.custommodaleditexamdetails textarea {
  width: 100%;
  padding: 20px;
  border-radius: 3px;
}
.custommodaleditexamdetails .hrpadding {
  padding-bottom: 0px;
}

.charcount {
  text-align: right;
  font-size: 12px;
  padding-right: 15px;
}

.generate-report-without-images-modal .firstpage > *:nth-last-child(2) {
  margin-bottom: 20px;
}

.generate-report-without-images-modal h5 {
  margin-top: 50px;
}

.generate-report-without-images-modal h5:first-child {
  margin-top: 20px;
}

.grader-report-eye-container.readings .aggregate-table-container {
  margin-bottom: 0;
}

.grader-report-eye-container.readings .va-selection-container {
  margin: 0;
}

.grader-report-eye-container.readings .right-eye-heading {
  display: flex;
  align-items: center;
}

.grader-report-eye-container.readings .va-subheading {
  margin-top: 0;
}

.grader-report-eye-container.readings input {
  margin-right: 10px;
}

.fslightbox-container .img-caption-heading,
.fslightbox-container .img-caption-timestamp,
.fslightbox-container .fslightbox-slide-number-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.fslightbox-container .img-caption-heading {
  font-weight: normal;
  font-size: 1.5em;
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #fff !important;
  --rmsc-selected: #fff !important;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 41px !important; /* Height */
}

.rmsc .select-item {
  margin-bottom: 0;
}

.rmsc {
  font-size: 14px;
  min-width: 230px;
}

.select-panel .options > label:first-child {
  border-bottom: 1px solid #eee;
}

.secondary-sites-users {
  color: #39b54a;
  font-weight: bold;
}

.secondary-site-tooltip-item {
  padding: 5px 5px;
}

.secondary-site-tooltip-item:last-child {
  padding: 0px 5px 5px;
  margin: 0px;
}

.grader-container {
  display: flex;
  align-items: stretch;
  height: 100vh;
  width: 100vw;
  font-size: 0.85rem;
  overflow: hidden;
}

.slideshow-container,
.grader-report-container {
  width: 50%;
  height: 100%;
}

.slideshow-container {
  background-color: #000;
}

.slideshow-container .image-container {
  height: calc(100vh - 91px) !important;
}

.slideshow-container .filter-container {
  height: 56px;
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 20px;
  width: 50%;
  z-index: 100;
}

.slideshow-container .filter-container .filter-item-container {
  display: flex;
  margin-right: 14px;
  border-radius: 10px;
  background-color: #232323;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 39px;
  height: 40px;
}

.slideshow-container .filter-container .filter-item-container.selected {
  border: 1px solid #30bd6d;
}

.slideshow-container .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  display: none;
}

.slideshow-container .loading-container.show {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: black;
}

.slideshow-container .loading-container img {
  width: 40px;
}

.slideshow-container .filter-container .filter-item-container img {
  /* margin-right: 20px; */
}

.slideshow-container .grader-image {
  height: 100%;
}

.grader-report-container {
  padding: 50px;
  color: #485560;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.grader-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
  margin-bottom: 10px;
  font-weight: bold;
}

.grader-details-container:last-child {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.grader-report-name {
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 50px;
}

.grader-report-heading {
  font-weight: normal;
}

.grader-report-container .diagnosis-heading {
  margin: 50px 0;
  font-weight: bold;
}

.grader-report-eye-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 15px;
  margin-bottom: 30px;
}

.generate-report-without-images-modal .name {
  font-size: 1.25rem;
  font-weight: bold;
}

.generate-report-without-images-modal .grader-details-container {
  font-size: 0.9rem;
}
.lg-sub-html .grader-eye-label {
  font-size: 16px !important;
  margin-top: 60px !important;
}

.grader-report-eye-container .right-eye-details {
  min-width: 0;
}
.grader-report-container input[type="checkbox"],
.slideshow-container input[type="checkbox"] {
  display: inline-block;
}

.grader-report-container .right-eye-details .checkbox:nth-child(2) {
  margin-left: 50px;
}

.grader-report-container .right-eye-details .checkbox:nth-child(3) {
  display: block;
}

.grader-report-container .ungradeable-text {
  vertical-align: top;
}

.grader-report-container .checkbox,
.grader-report-container .radio-label {
  margin: 0;
  display: inline-block;
  vertical-align: top;
}

.grader-report-container .rmsc {
  margin-bottom: 15px;
}

.grader-report-container textarea {
  border-color: #ccc;
  border-radius: 4px;
  width: 100%;
  height: 100px;
  padding: 10px;
}

.grader-report-container .refer {
  display: grid;
  grid-template-columns: 1.2fr 3fr;
  margin-top: 45px;
}

.grader-report-container .refer-heading {
  font-weight: bold;
}

.grader-report-container .radio-label.first {
  margin-right: 30px;
}

.generate-report-modal .refer {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  margin-top: 45px;
}

.generate-report-modal .refer-heading {
  font-weight: bold;
}

.generate-report-modal .radio-label.first {
  margin-right: 30px;
}

.slideshow-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
  margin: 10px 30px 0px;
  color: white;
}

.slideshow-container .number-of-images {
  text-align: right;
  color: #999;
  margin: 0px 30px 20px;
}

.abovetable.not-visible,
.exam-options.not-visible {
  display: none;
}

.slideshow-container .counter-container {
  text-align: center;
  color: #eee;
  position: fixed;
  bottom: 20px;
  width: 50%;
  z-index: 100;
}

.slideshow-container .checkbox {
  margin: 0;
  display: inline-block;
}

.slideshow-container .ungradeable-text {
  vertical-align: top;
}

.slideshow-container .lg-outer .lg-image {
  /* height: 100%; */
}

.slideshow-container .lg-outer .lg-components {
  bottom: 40px;
}
.slideshow-container select[name="site"] {
  background-color: black;
  color: white;
  border: none;
}

.slideshow-container .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-container input[type="checkbox"] {
  vertical-align: middle;
  position: relative;
  top: 3px;
}

.generate-report-container button {
  width: 200px;
  margin-right: 0;
  margin-top: 45px;
  float: none !important;
}

.generate-report-modal .checkbox {
  margin: 0;
  display: inline-block;
  vertical-align: top;
}

.generate-report-modal .refer > div {
  display: flex;
}
.generate-report-container {
  margin-bottom: 45px;
  text-align: right;
}
.custommodalinner.notification-settings {
  overflow: none;
}
.secondary-sites-arrow {
  color: #39b54a;
}

.secondary-sites-users .dropdown {
  display: inline;
}

.secondary-sites-users ul {
  margin: 0;
}

.secondary-sites-users .dropdown-menu {
  color: rgba(77, 77, 77, 1);
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #dadde9;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
  max-height: 500px;
  overflow: scroll;
}

.statistics-page {
  margin-top: 30px;
}

.statistics-page .statistics-heading {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.statistics-page .filters-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-size: 0.8rem;
}

.statistics-page .site-container {
  margin-left: 50px;
  flex-basis: 40%;
}

.statistics-page .filters-container input[type="radio"] {
  margin-right: 5px;
}

.statistics-page .site-options-container {
  display: flex;
}

.statistics-page .site-container-heading,
.statistics-page .timeline-container-heading {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.statistics-page .filter-group {
  margin-right: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistics-page .statistics-modal .chart-container {
  width: 80%;
}

.statistics-page .filter-group label {
  margin: 0;
}

.statistics-page .see-more-container.diagnoses {
  margin-top: 50px;
}

.statistics-page .custom-tooltip {
  padding: 5px;
}

.statistics-page .custom-tooltip .tooltip-title {
  font-weight: bold;
}

.statistics-page .statistics-select-site {
  width: 178px;
  border: 1px solid #ccc;
  padding: 10px 21px 10px 10px;
  border-radius: 5px;
}

.statistics-page .month-picker input[type="text"] {
  border: 1px solid #cccccc;
  padding: 10px;
  padding-right: 21px;
  border-radius: 5px;
}

.statistics-page .month-picker {
  display: inline-block;
}

.statistics-page .bottom-section {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-bottom: 30px;
}

.statistics-page .tabs-container {
  display: flex;
  justify-content: space-between;
}

.statistics-page .tab {
  flex-basis: 25%;
}

.statistics-page .tab-container {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
}

.statistics-page .tab-container .tab-heading {
  font-size: 0.8rem;
}

.statistics-page .tab:first-child .tab-container {
  border-top-left-radius: 10px;
}

.statistics-page .tab:last-child .tab-container {
  border-top-right-radius: 10px;
}

.statistics-page .tab .tab-data {
  font-size: 1.5rem;
  font-weight: bold;
}

.statistics-page .tab.selected {
  border-bottom: 9px solid #30bd6d;
  background-color: #fbfbfb;
}

.statistics-page .tab.selected .tab-container {
  height: calc(100% + 9px);
}

.statistics-page .chart-title {
  margin: 3em;
  text-align: center;
  font-size: 1rem;
}

.statistics-page .no-results-container img {
  height: 270px;
}

.statistics-page .statistics-modal .chart-title {
  margin: 0px 50px;
}

.statistics-page .statistics-modal .title-container {
  margin-top: 20px;
  margin-bottom: 50px;
}

.statistics-page .statistics-modal .chart-container .chart {
  width: 100%;
}
.statistics-page .chart-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.statistics-page .chart-container .chart {
  width: 50%;
}

.statistics-page .chart-container .chart.thirty {
  width: 100%;
}

.statistics-page .graph-container .chart-title.paginated {
  display: inline-block;
}

.statistics-page .title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statistics-page .graph-container .previous,
.statistics-page .graph-container .next {
  display: inline-block;
  cursor: pointer;
}

.statistics-page .graph-container .previous.disabled,
.statistics-page .graph-container .next.disabled {
  cursor: default;
}

.statistics-page .graph-container .previous.disabled img,
.statistics-page .graph-container .next.disabled img {
  -webkit-filter: opacity(50%);
  -moz-filter: opacity(50%);
  -o-filter: opacity(50%);
  -ms-filter: opacity(50%);
  filter: opacity(50%);
}

.statistics-page .see-more-container {
  text-align: right;
  width: 90%;
  margin: auto;
  font-size: 0.8rem;
}

.statistics-page .see-more-container button {
  border: 1px solid #30bd6d;
  background-color: white;
  color: #30bd6d;
  border-radius: 5px;
  padding: 10px 20px;
}

.logout {
  border: 1px solid #30bd6d;
  background-color: #30bd6d;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.statistics-page .firstpage {
  padding-bottom: 40px;
}

.statistics-page .custommodalinner {
  width: 50vw !important;
  left: 25% !important;
}

.statistics-page .custommodalinner .modal-heading {
  font-size: 18px;
}

.statistics-page .chart-container .chart.no-results-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.statistics-page .loginpad {
  padding: 60px;
}

.exam-details-page .exam-dark-background {
  background-color: #fbfbfb;
}

.adminoption {
  cursor: pointer;
}

.exam-details-page .new-exam-details-header {
  margin-top: 50px;
  margin-left: 20px;
  position: relative;
}

.about-main {
  text-align: center;
  margin-bottom: 30px;
  font-size: 0.8rem;
}

.about-container .loginpad {
  position: relative;
}

.about-address .about-heading {
  font-weight: bold;
  font-size: 1rem;
}

.about-address {
  margin-bottom: 30px;
  font-size: 0.8rem;
}

.regulatory a {
  color: #45c9f8 !important;
  position: relative;
  left: 20px;
  cursor: pointer;
  font-size: 0.8rem;
}

.regulatory img {
  width: 45px;
}

.regulatory {
  margin-bottom: 10px;
}

.about-container .hrpadding {
  padding: 0;
}

.about-main img {
  position: relative;
  left: 12px;
  margin-bottom: 35px;
  width: 120px;
}

.about-bottom {
  position: absolute;
  bottom: 20px;
}

.about-main p {
  margin: 0;
}

.exam-details-page .exam-back {
  position: relative;
  left: -40px;
  cursor: pointer;
}

.exam-details-page .new-exam-details-patient-name {
  display: inline-block;
  position: absolute;
  font-size: 1.4rem;
  top: 0;
  left: 0;
  font-weight: bold;
}

.exam-details-page .gen-btn-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.exam-details-page .gen-btn-container button,
.exam-details-page .gen-btn-container button:hover {
  width: 100%;
  background-color: #30bd6d;
  border: none;
  margin-right: 10px;
  width: 175px;
}

.exam-details-page .new-exam-details-patient-details {
  margin-top: 25px;
  font-size: 0.9rem;
}

.exam-details-page .new-exam-details-value {
  font-weight: 650;
  line-height: 32.75px;
}

.exam-details-page .new-exam-details-patient-details-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.exam-details-page .download-exam-btn,
.exam-details-page .download-exam-btn:hover,
.exam-details-page .download-exam-btn:active {
  border: 1px solid #30bd6d !important;
  background-color: #fbfbfb !important;
  color: #30bd6d !important;
  border-radius: 5px;
  padding: 10px 10px;
  width: 110px;
  font-size: 0.8rem;
}

.exam-details-page .generate-report-btn,
.exam-details-page .generate-report-btn:hover,
.exam-details-page .generate-report-btn:active {
  border: 1px solid #30bd6d;
  background-color: #30bd6d;
  color: white;
  border-radius: 5px;
  padding: 10px 10px;
  width: 110px;
  font-size: 0.8rem;
}

.exam-details-page .page-content-container {
  margin-top: 30px;
  background-color: white;
  border: 1px solid #dbdbdb;
}

.exam-details-page .tabs-container {
  display: flex;
  justify-content: space-between;
}

.exam-details-page .tab {
  flex-basis: 33%;
}

.exam-details-page .tab-container {
  height: 70px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
}

.custommodalinner .collapsible {
  /* transition: transform3d(0, 0, 0); */
}

.custommodalinner .collapsible img {
  cursor: pointer;
  position: relative;
  bottom: 1px;
  width: 15px;
}

.new-dob {
  margin-bottom: 1rem;
}

.custommodalinner .col-heading * {
  display: inline-block;
}

.custommodalinner .col-heading h6 {
  margin-right: 10px;
}

.custommodalinner .collapsible.collapsed {
  height: 50px;
  overflow: hidden;
}

.exam-details-page .tab-container .tab-heading {
  font-size: 1rem;
}

.exam-details-page .tab:first-child .tab-container {
  border-top-left-radius: 10px;
}

.exam-details-page .tab:last-child .tab-container {
  border-top-right-radius: 10px;
}

.exam-details-page .tab.selected {
  border-bottom: 5px solid #30bd6d;
}

.exam-details-page .tab.selected .tab-container {
  height: calc(100% + 5px);
}

.exam-details-page .content-container {
  margin-top: 10px;
  padding: 20px 100px;
}

.exam-details-page .no-values-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.exam-details-page .no-values-container p {
  text-align: center;
  width: 400px;
}

.exam-details-page .no-values-container img {
  width: 300px;
  margin-bottom: 30px;
}

.exam-details-page .readings-big-container {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  text-align: center;
  justify-items: center;
  align-items: center;
}

.table-readings > * {
  padding: 7px;
}

.table-readings .table-aggregate {
  background-color: #c8cccf;
  font-weight: bold;
}
.table-readings .table-row-heading {
  background-color: #c8cccf;
}

.table-readings .table-contents {
  background-color: #f7f7f7;
}

.table-readings .top-left {
  border-top-left-radius: 5px;
}

.table-readings .top-right {
  border-top-right-radius: 5px;
}

.table-readings .bottom-left {
  border-bottom-left-radius: 5px;
}

.table-readings .bottom-right {
  border-bottom-right-radius: 5px;
}

.exam-details-page .reading-container {
  display: flex;
  flex-direction: column;
}

.table-readings {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
}

.exam-details-page .table-heading {
  margin-bottom: 20px;
}

.exam-details-page .row-title:first {
  position: relative;
  top: 32px;
}

.exam-details-page .row-title {
  font-weight: bold;
  color: #30bd6d;
}

.va-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  /* text-align: center; */
}

.va-selection-container {
  display: flex;

  margin: 20px auto;
}

.custommodalinner.generate-report-modal {
  width: 735px !important;
}

.aggregate-table-container-container .aggregate-table-container {
  margin-right: 50px;
}

.aggregate-subheading {
  text-align: center;
  margin-right: 50px;
  font-weight: bold;
  margin-bottom: 5px;
}

.va-subheading {
  font-weight: bold;
  /* text-align: center; */
  margin-top: 30px;
}

.va-selection-container .va-radio {
  margin-right: 20px;
}

.aggregate-table-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.aggregate-table-container > * {
  padding: 7px;
  height: 38px;
}

.prescription-container .formfont {
  padding-left: 15px;
}

.aggregate-table-container .aggregate-table-contents {
  background-color: #f7f7f7;
}

.aggregate-table-container .aggregate-table-heading {
  background-color: #c8cccf;
}

.aggregate-table-container .top-left {
  border-top-left-radius: 5px;
}

.aggregate-table-container .top-right {
  border-top-right-radius: 5px;
}

.aggregate-table-container .bottom-left {
  border-bottom-left-radius: 5px;
}

.aggregate-table-container .bottom-right {
  border-bottom-right-radius: 5px;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad {
    width: 16rem !important;
  }
  .month-picker > .rmp-container .rmp-popup.light {
    width: 16rem;
  }
}

.active-directory-btn {
  margin-bottom: 30px;
  margin-top: 60px;
}

.offline-warning {
  margin-top: 20px;
  font-size: 12px;
  margin-left: 15px;
}

.separator {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.separator .line {
  height: 1px;
  flex: 1;
  background-color: #c8cccf;
}

.separator span {
  padding: 0 1rem;
}

.or-text {
  color: #c8cccf;
}
